// 接口地址
const apiUrl = "http://smart-file.hehaokeji.com/";
const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://test.lidian-icharging.com"
    : "https://www.lidian-icharging.com";

export default {
  apiUrl,
  baseUrl,
};
