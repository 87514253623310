import { getCurrentInstance, onUnmounted, ref } from 'vue';

// 访问vuex
export const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$store;
};
// 访问router
export const useRouter = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$router;
};
// 访问route
export const useRoute = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$route;
};

/**
 * 创建一个支持轮询的组合式函数
 * @param {Function} callback - 轮询时执行的回调函数
 * @param {Number} interval - 轮询时间间隔（毫秒）
 */
export function usePolling(callback, interval = 5000, immediate = false) {
  const isPolling = ref(false);
  let timerId = null;

  function startPolling() {
    if (!isPolling.value) {
      isPolling.value = true;
      if (immediate) callback();
      timerId = setInterval(callback, interval);
    }
  }

  function stopPolling() {
    if (isPolling.value) {
      clearInterval(timerId);
      isPolling.value = false;
    }
  }
  // 控制轮询的开关
  function togglePolling() {
    if (isPolling.value) {
      stopPolling();
    } else {
      startPolling(callback);
    }
  }

  onUnmounted(() => {
    stopPolling();
  });

  return {
    startPolling,
    stopPolling,
    togglePolling,
    isPolling,
  };
}
